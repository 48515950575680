/* istanbul ignore file */
import Cookies from "js-cookie";

export class BrowserStorageHelper {
  // set item with the key
  static setItem(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  static setUserInfo(value, key) {
    const userFromStorage = BrowserStorageHelper.getItem(key) || {};
    const userInfo = { ...userFromStorage, ...value };
    BrowserStorageHelper.setItem(key, userInfo);
  }

  // get item with the key
  static getItem(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
  }

  // remove item with the key
  static removeItem(key) {
    return localStorage.removeItem(key);
  }

  static createLsName(applicationID) {
    return `CAD:${applicationID}`;
  }

  static getLsName(applicationID) {
    return BrowserStorageHelper.createLsName(applicationID);
  }

  static createLsNameFromAxios(axiosInstance) {
    const { application_id: applicationId } = axiosInstance.defaults.headers;
    if (!applicationId) return false;
    return BrowserStorageHelper.createLsName(applicationId);
  }

  static getCookieItem(key = "cadent.aperture") {
    const cadentCookie = Cookies.get(key);
    let cookieResults;

    if (cadentCookie) {
      const decodeCookie = decodeURIComponent(cadentCookie);
      const parsedCookie = JSON.parse(`${decodeCookie}`);
      cookieResults = parsedCookie;
    }
    return cookieResults || null;
  }

  static getRawCookieItem(key = "cadent.aperture") {
    const cookie = Cookies.get(key);
    return cookie || null;
  }
}

export default BrowserStorageHelper;
