export const STATUSES = {
  COOKIES_EXIST: "cookies exist",
  DECODED_COOKIES: "decoded and decompressed cookies",
  ENTITIES_REQUEST: "requesting user entities",
  ENTITIES_SUCCESS: "requesting user entities was successful",
  ENTITIES_FAILURE: "requesting user entities has failed",
  USER_AUTHENTICATED: "user is authenticated",
  USER_ON_PUBLIC_URL: "user is on a public url",
  UPDATE_ENTITIES_REQUEST: "requesting update entities",
  UPDATE_ENTITIES_SUCCESS: "requesting update entities was successful",
  UPDATE_ENTITIES_FAILURE: "requesting update entities has failed",
  REFRESH_AUTH_TOKEN_REQUEST: "requesting auth token refresh",
  REFRESH_AUTH_TOKEN_SUCCESS: `requesting auth token refresh was successful`,
  REFRESH_AUTH_TOKEN_FAILURE: "requesting auth token refresh has failed",
  LOGOUT: "logging out",
  REDIRECT_TO_ULP: "redirecting to ULP",
};

export const PERMISSIONS = {
  ADVERTISERS: [
    "AdvertisersCreate",
    "AdvertisersDelete",
    "AdvertisersRead",
    "AdvertisersUpdate",
  ],
  AGENCIES: [
    "AgenciesCreate",
    "AgenciesDelete",
    "AgenciesRead",
    "AgenciesUpdate",
  ],
  UM_USER: ["UmUserCreate", "UmUserDelete", "UmUserRead", "UmUserUpdate"],
  MANAGE_ADVERTISERS: [
    "AdvertisersCreate",
    "AdvertisersDelete",
    "AdvertisersUpdate",
  ],
};

export const STATES = {
  ERROR: "ERROR",
  ULP_REDIRECT: "ULP_REDIRECT",
  SETTING_APPLICATION_AUTH: "SETTING_APPLICATION_AUTH",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  RETURN_TO_REDIRECT: "RETURN_TO_REDIRECT",
  USER_LOGOUT: "USER_LOGOUT",
  EXT_APPLICATION_LOGOUT: "EXT_APPLICATION_LOGOUT",
  TOKEN_REFRESH: "TOKEN_REFRESH",
  TOKEN_EXPIRED: "TOKEN_EXPIRED",
  INVALID_TOKEN: "INVALID_TOKEN",
  PERMISSIONS_MISSING: "PERMISSIONS_MISSING",
};

export const ERROR_MESSAGE = {
  INVALID_TOKEN: "The token structure is invalid.",
  ENVIRONMENT_NOT_SET: "Environment not set.",
  USER_DOES_NOT_HAVE_ACCESS: "User does not have access.",
  ACCESS_TOKENS_MISSING: "Access token cookies not set.",
  PERMISSIONS_MISSING: "Permissions are not set.",
  NO_AUTH_INITIALIZED: "Authentication not initialized",
  AUTH_FAILED: "Invalid access. Authentication failed!",
};

const {
  ERROR,
  ULP_REDIRECT,
  SETTING_APPLICATION_AUTH,
  LOGIN_SUCCESS,
  RETURN_TO_REDIRECT,
  USER_LOGOUT,
  EXT_APPLICATION_LOGOUT,
  TOKEN_REFRESH,
  TOKEN_EXPIRED,
  INVALID_TOKEN,
} = STATES;

export const status = {
  error: (err) => ({ type: ERROR, payload: { err } }),
  ulpRedirect: (url) => ({ type: ULP_REDIRECT, payload: { url } }),
  settingAuthToken: (auth) => ({
    type: SETTING_APPLICATION_AUTH,
    payload: { auth },
  }),
  loginSuccess: (auth) => ({
    type: LOGIN_SUCCESS,
    payload: { auth },
  }),
  returnToRedirect: (url) => ({
    type: RETURN_TO_REDIRECT,
    payload: { url },
  }),
  userLogout: (auth) => ({
    type: USER_LOGOUT,
    payload: { auth },
  }),
  extAppLogout: () => ({
    type: EXT_APPLICATION_LOGOUT,
  }),
  tokenRefresh: (payload) => ({
    type: TOKEN_REFRESH,
    payload,
  }),
  tokenExpired: (payload) => ({
    type: TOKEN_EXPIRED,
    payload,
  }),
  invalidToken: (payload) => ({
    type: INVALID_TOKEN,
    payload,
  }),
};
