/* eslint-disable no-underscore-dangle */
import pako from "pako";
import jwtDecode from "jwt-decode";
import isEmpty from "lodash.isempty";
import { isExpired, doesUserHaveAccess } from "../utils/tokenUtils";
import BrowserStorageHelper from "../utils/browser-storage";
import {
  createAxiosInstance,
  ulpApiBase,
  getUserManagementUrl,
  getAdvertiserManagementUrl,
  getAgenciesManagementUrl,
  getChangePasswordUrl,
  redirectToUlp,
} from "../utils/urlUtils";
import { filterVisibleEntities } from "../utils/helpers";
import { PERMISSIONS, status, ERROR_MESSAGE, STATUSES } from "./authStatus";

const redirectCtName = "cad_unified_redirect_ct";
let envCache = null;

class Auth {
  config = {
    // ULP Application ID
    __ULPID: "B64E86F1-8076-41E5-868C-BF58DB4E108F",
    __namespaceDomain: "domain=.cadent.tv",
    __path: "path=/",

    // End point paths
    __URLS: {
      allEntities: "User/entity/all",
      entitySwitch: "User/entity/switch",
      refresh: "Security/refresh",
    },

    // User Data key
    __userDataKey: "user_data",
    __userDataLegacyKey: "userdata",

    // Cookie environment name map
    __cookieEnv: {
      local: "d",
      development: "d",
      qa: "q",
      demo: "u",
      stage: "s",
      production: "p",
    },
  };

  // Request props
  __REQUEST = {
    INSTANCE: null,
    GET: null,
    POST: null,
  };

  constructor() {
    this.setDefaults();
  }

  setDefaults() {
    this.appId = null;
    this.env = "production";
    this.userData = null;
    this.refreshData = null;
    this.permissions = null;
    this.activePermission = null;
    this.accessToken = null;
    this.refreshToken = null;
    this.expired = null;
    this.entityId = null;
    this.isInternalApp = null;
    this.entities = null;
    this.CATID = null;
    this.CRTID = null;
    this.onAuthenticated = null;
    this.onNotAuthenticated = null;
    this.onError = null;
    this.disableRedirects = false;
    this.initialUserPath = "";
    this.__ulpPermissions = null;
    this.routerBaseName = "";
    this.setAuthError = null;
    this.isPublic = false;
  }

  /**
   * Initialize
   * @param {string} appId Applications ID
   * @param {string} env Current application environment
   * @param {boolean} isInternalApp flag if this is being consumed by internal app
   * @param {function} onAuthenticated method triggered when the user has been fully authenticated and auth package is ready
   * @param {function} onNotAuthenticated method that triggers if the user is NOT authenticated and will directed to logout
   * @param {function} onError method to trigger soft errors
   * @param {boolean} disableRedirects flag to prevent auth package from redirecting the page
   * @param {string} initialUserPath the initial user path requested before routing user to ULP
   * @param {string} routerBaseName Base route path for user's current app e.g /release-web, /dashboard, etc
   * @param {function} setAuthError Function passed by React Auth package to trigger Error Modal in case of Infinite loop condition
   */
  async init({
    appId,
    env,
    isInternalApp = false,
    onAuthenticated,
    onNotAuthenticated,
    onError,
    onStatusUpdate,
    disableRedirects = false,
    initialUserPath = "",
    routerBaseName = "",
    setAuthError,
    isPublic = false,
  }) {
    this.appId = appId;
    this.env = env;
    envCache = env;
    this.isInternalApp = isInternalApp;
    this.onAuthenticated = onAuthenticated;
    this.onNotAuthenticated = onNotAuthenticated;
    this.onError = onError;
    this.onStatusUpdate = onStatusUpdate;
    this.disableRedirects = disableRedirects;
    this.initialUserPath = initialUserPath;
    this.routerBaseName = routerBaseName;
    this.setAuthError = setAuthError;
    this.isPublic = isPublic;

    // set cookie ids
    this.CATID = this.__getAccessTokenName(env);
    this.CRTID = this.__getRefreshTokenName(env);

    // get access and refresh tokens
    this.accessToken = this.__getCookie(this.CATID);
    this.refreshToken = this.__getCookie(this.CRTID);

    // check if access token is set
    this.__checkAccessToken();
    this.__onStatus(STATUSES.COOKIES_EXIST);

    // stop init if access token is not present
    if (!this.accessToken) {
      if (this.onError) this.__missingTokensError(this.onError);
      return;
    }

    // set uer data
    this.userData = this.accessToken ? this.__decodeJWT(this.accessToken) : {};
    this.refreshData = this.refreshToken ? jwtDecode(this.refreshToken) : {};
    this.__onStatus(STATUSES.DECODED_COOKIES);
    this.expired = this.__isTokenExpired();

    // get entities
    this.entityId = this.getActiveEntity();
    this.entities = await this.getEntities();

    // get users permissions
    this.permissions = this.__getUserAllPermissions();
    this.activePermission = this.__getUserActivePermissions();
    this.__ulpPermissions = this.__getULPPermissions();

    // set auth on the window object for debugging/testing purposes
    window.__CDNTAuth__ = this;

    // check if user has valid token/data/permissions
    this.checkAuthentication(this.onAuthenticated);
  }

  /*
    Public Functions
  */
  /**
   * Checks if user has valid token/data/permissions
   * @param {function} onAuthenticated method triggered when the user has been authenticated
   * @returns
   */
  checkAuthentication(onAuthenticated = null) {
    // if the url is public we do not need to check credentials
    if (this.isPublic) {
      this.__onStatus(STATUSES.USER_ON_PUBLIC_URL);
      BrowserStorageHelper.removeItem(redirectCtName);
      onAuthenticated();
    } else if (!this.expired && !this.activePermission) {
      /*
       * if user is not having active permissions for current appId;
       * we are redirecting to ULP instead of onAuthenticated call
       */
      this.__redirectToUlp(true);
    }

    // token expired or user data not present
    else if (this.expired || isEmpty(this.userData)) {
      this.__logout();
    }

    // user is authenticated
    else if (!this.expired && !isEmpty(this.userData)) {
      this.__onStatus(STATUSES.USER_AUTHENTICATED);
      BrowserStorageHelper.removeItem(redirectCtName);
      this.setAuthError?.(null);
      if (onAuthenticated && typeof onAuthenticated === "function") {
        onAuthenticated();
      }
    }
  }

  /**
   * Check if the current user is authenticated
   * @returns {boolean}
   */
  isAuthenticated() {
    return !this.expired && !isEmpty(this.userData) && !!this.activePermission;
  }

  /**
   * Get current user data
   * @returns {object} user name and permissions
   */
  getUser() {
    if (!this.userData) return {};

    return {
      user: {
        first: this.userData.given_name,
        last: this.userData.family_name,
        email: this.userData.sub,
      },
      CanChangePassword: this.userData.user_data.CanChangePassword,
      ...this.activePermission,
      ap_acl: this.userData.ap_acl,
      as_acl: this.userData.as_acl,
      cm_acl: this.userData.cm_acl,
    };
  }

  /**
   * Get the current entities the user has accessed
   * @param {*} onFail
   * @returns
   */
  async getEntities(onFail) {
    if (this.entities?.length) return filterVisibleEntities(this.entities);
    // prevent Api call if JsAuth is not initialized
    if (!this.appId) {
      if (onFail) {
        onFail(
          status.error({
            message: ERROR_MESSAGE.NO_AUTH_INITIALIZED,
            success: false,
          })
        );
      }
      return [];
    }

    this.__setupRequests();
    const entityUrl = `${ulpApiBase[this.env]}/${
      this.config.__URLS.allEntities
    }`;

    try {
      this.__onStatus(STATUSES.ENTITIES_REQUEST);
      const response = await this.__REQUEST.GET(entityUrl);
      const {
        data: { success, resultList },
      } = response;

      if (!success && this.onError) {
        this.onError(status.error(response));
        this.__onStatus(STATUSES.ENTITIES_FAILURE);
      }
      if (!success && onFail) {
        onFail(response);
        this.__onStatus(STATUSES.ENTITIES_FAILURE);
      }

      this.__onStatus(STATUSES.ENTITIES_SUCCESS);
      return filterVisibleEntities(resultList);
    } catch (err) {
      if (this.onError) this.onError(status.error(err));
      if (onFail) onFail(err);
      this.__onStatus(STATUSES.ENTITIES_FAILURE);
      return [];
    }
  }

  // return active entity
  getActiveEntity() {
    if (!this.entities?.length) {
      return this.userData?.entity_id;
    }

    // loop over user entities and return the entity the is `isCurrent: true`
    const current = this.entities.find(({ isCurrent }) => isCurrent);

    // default to users JWT entity_id if not current entity is found
    return current?.entityId || this.userData?.entity_id;
  }

  // Update the users current entity access
  async updateCurrentEntity(
    entity,
    opts = { onEntityUpdate: null, refresh: true, onFail: null }
  ) {
    if (!entity || entity === "") return false;

    this.__setupRequests();
    const entitySwitchUrl = `${ulpApiBase[this.env]}/${
      this.config.__URLS.entitySwitch
    }/${entity}`;

    try {
      this.__onStatus(STATUSES.UPDATE_ENTITIES_REQUEST);
      const response = await this.__REQUEST.POST(entitySwitchUrl);
      const {
        data: { success, result },
      } = response;

      if (!success) {
        if (this.onError) this.onError(status.error(response));
        if (opts?.onFail) opts.onFail(response);
        this.__onStatus(STATUSES.UPDATE_ENTITIES_FAILURE);
        return;
      }

      // update access and refresh token
      if (success && result?.unifiedAccessToken)
        this.__updateAccessToken(result.unifiedAccessToken);

      if (success && result?.unifiedRefresh?.token)
        this.__updateRefreshToken(result.unifiedRefresh.token);

      this.__onStatus(STATUSES.UPDATE_ENTITIES_SUCCESS);

      // refresh the current window (defaults to true)
      if (!opts?.refresh && typeof opts?.onEntityUpdate === "function") {
        opts.onEntityUpdate();
      } else {
        window.location.reload();
      }
    } catch (err) {
      if (this.onError) this.onError(status.error(err));
      this.__onStatus(STATUSES.UPDATE_ENTITIES_FAILURE);
    }
  }

  /**
   * Checks if user has access to the permission and applicationId passed from param
   * @param {string} permissionName Name of permission to be checked in user access
   * @param {string} applicationId Id of application to be checked in user access
   * @returns {boolean}
   */
  checkAccessByPermissionName(permissionName, applicationId) {
    const appIdForSearch = applicationId || this.appId;
    if (!permissionName || !appIdForSearch || !this.permissions?.Auth?.length) {
      return false;
    }

    const appAccessData = this.permissions.Auth.find(
      ({ ApplicationId }) => ApplicationId === appIdForSearch
    );

    return appAccessData?.Permissions
      ? appAccessData.Permissions.includes(permissionName)
      : false;
  }

  // Get user current access token encoded
  getToken() {
    return this.accessToken;
  }

  /**
   * Get User Management link
   * @param {function} onFail error callback
   * @returns {string} url to user management
   */
  getUserManagementLink(onFail = null) {
    if (!this.env) {
      if (onFail) this.__environmentError(onFail);
      return "";
    }

    if (!this.__hasPermissions(this.__ulpPermissions)) return "";

    // check if the user has any of the required privileges
    const hasAccess = doesUserHaveAccess(
      this.__ulpPermissions.Permissions,
      PERMISSIONS.UM_USER
    );

    return this.__checkAccess(
      hasAccess,
      () => getUserManagementUrl(this.env),
      onFail
    );
  }

  /**
   * Get Advertisers Management link
   * @param {function} onFail error callback
   * @returns {string} url to advertisers management
   */
  getAdvertiserMgmtLink(onFail = null) {
    if (!this.env) {
      if (onFail) this.__environmentError(onFail);
      return "";
    }

    if (!this.__hasPermissions(this.__ulpPermissions)) return "";

    // check if the user has any of the required privileges
    const hasAccess = doesUserHaveAccess(
      this.__ulpPermissions.Permissions,
      PERMISSIONS.MANAGE_ADVERTISERS
    );

    return this.__checkAccess(
      hasAccess,
      () => getAdvertiserManagementUrl(this.env),
      onFail
    );
  }

  /**
   * Get Agencies Management link
   * @param {function} onFail error callback
   * @returns {string} url to agencies management
   */
  getAgenciesMgmtLink(onFail = null) {
    if (!this.env) {
      if (onFail) this.__environmentError(onFail);
      return "";
    }

    if (!this.__hasPermissions(this.__ulpPermissions)) return "";

    // check if the user has any of the required privileges
    const hasAccess = doesUserHaveAccess(
      this.__ulpPermissions.Permissions,
      PERMISSIONS.AGENCIES
    );

    return this.__checkAccess(
      hasAccess,
      () => getAgenciesManagementUrl(this.env),
      onFail
    );
  }

  /**
   * et Change Password link
   * @param {function} onFail error callback
   * @returns {string} url for change password
   */
  getChangePasswordLink(onFail = null) {
    if (!this.permissions) {
      if (onFail) this.__missingPermissionsError(onFail);
      return "";
    }

    if (!this.env) {
      if (onFail) this.__environmentError(onFail);
      return "";
    }

    if (!this.permissions?.CanChangePassword) {
      if (onFail) onFail();
      return "";
    }

    return getChangePasswordUrl(this.env);
  }

  /**
   * Trigger call to refresh access & refresh token
   * @param {function} onSuccess update success callback
   * @param {function} onFail update failure callback
   */
  async refreshAuthTokens(onSuccess = null, onFail = null) {
    // check if tokens exist
    this.__checkRefreshToken();
    if (!this.accessToken || !this.refreshToken) {
      if (this.onError) this.__missingTokensError(this.onError);
      return;
    }

    // check for refresh token expiration and if it's past current time
    const currentTime = Math.trunc(Date.now() / 1000);

    if (!this.refreshData || this.refreshData?.exp < currentTime) {
      onFail();
      this.__logout();
      return;
    }

    try {
      this.__onStatus(STATUSES.REFRESH_AUTH_TOKEN_REQUEST);
      this.__setupRequests();
      const refreshUrl = `${ulpApiBase[this.env]}/${
        this.config.__URLS.refresh
      }`;
      const response = await this.__REQUEST.POST(refreshUrl, {
        accessToken: this.accessToken,
        refreshToken: this.refreshToken,
      });
      const {
        data: { success, result },
      } = response;

      // response 200 but failed internally so trigger logout
      if (!success) {
        if (onFail) onFail();
        this.__onStatus(STATUSES.REFRESH_AUTH_TOKEN_FAILURE);
        this.__logout();
      }

      // update access and refresh token
      if (success && result?.unifiedAccessToken)
        this.__updateAccessToken(result.unifiedAccessToken);

      if (success && result?.unifiedRefresh?.token)
        this.__updateRefreshToken(result.unifiedRefresh.token);

      this.__onStatus(STATUSES.REFRESH_AUTH_TOKEN_SUCCESS);

      if (success && onSuccess) onSuccess();
    } catch (err) {
      if (onFail) onFail();
      this.__onStatus(STATUSES.REFRESH_AUTH_TOKEN_FAILURE);
      this.__logout();
    }
  }

  // ifUserLoggedOut is true if the user clicked the log out button
  __logout(ifUserLoggedOut = false) {
    this.__onStatus(STATUSES.LOGOUT);
    if (this.onNotAuthenticated) this.onNotAuthenticated();
    this.__removeAuthCookies();
    if (this.appId !== this.config.__ULPID) {
      this.__redirectToUlp(null, ifUserLoggedOut);
    }
    this.setDefaults();
  }

  // Log out the current user
  logout() {
    this.__logout(true);
  }

  // Function to get the access token name based on the environment
  __getAccessTokenName(env) {
    // Return the cookie environment variable with "_cat" appended to it
    return `${this.config.__cookieEnv[env]}_cat`;
  }

  // Function to get the refresh token name for a given environment
  __getRefreshTokenName(env) {
    // Return the cookie environment name with "_crt" appended to it
    return `${this.config.__cookieEnv[env]}_crt`;
  }

  /**
   * Get users current token from browser cookies
   * @param {string} id of the cookie
   * @returns {string} value of the cookie
   */
  __getCookie(id) {
    // retrieve jwt from cookies
    return BrowserStorageHelper.getRawCookieItem(id);
  }

  /**
   * Set cookie with the provided id and value
   * @param {string} id cookie identifier
   * @param {string} value cookie value
   * @param {number} exp cookie expiration date
   */
  __setCookie(id, value, exp) {
    document.cookie = `${id}=${value}; ${this.config.__namespaceDomain}; ${
      this.config.__path
    }; ${this.__getCookieExpiration(exp)}; ${this.__setCookieSecure()}`;
  }

  // check if we can set `secure` flag on the cookies
  // NOTE: user must be on HTTPS to set secure flag
  __setCookieSecure() {
    const isLocal = ["local", "development"].includes(this.env);
    return document.location.protocol === "https:" && !isLocal ? "secure" : "";
  }

  /**
   * Get the current expiration to set on cookie
   * @param {number} epoch value of cookies expiration
   */
  __getCookieExpiration(epoch) {
    // check if epoc was sent in short format ie (JS) use 13 digit format but the BE uses 10
    const expDate = epoch < 1600000000000 ? epoch * 1000 : epoch;
    return `expires=${new Date(expDate).toUTCString()}`;
  }

  /**
   * Sets cookie to current date/time for the browser to remove the cookie
   * @param {string} id cookie name
   * @param {string} value cookie content
   */
  __removeCookie(id, value) {
    this.__setCookie(`${id}=${value}`, "", Date.now());
  }

  // remove access/refresh token cookies
  __removeAuthCookies() {
    this.__removeCookie(this.CATID, "");
    this.__removeCookie(this.CRTID, "");
  }

  /**
   * Un-compress string values to human readable JSON
   * @param {string} compressedValue compressed string value
   */
  __unCompress(compressedValue) {
    const compressData = atob(compressedValue);
    const charCodeData = compressData.split("").map(function (e) {
      return e.charCodeAt(0);
    });
    const binData = new Uint8Array(charCodeData);
    const data = pako.inflate(binData);
    const final = String.fromCharCode.apply(null, new Uint16Array(data));

    return final;
  }

  /**
   * Decode the supplied JWT to human readable values
   * @param {string} token JWT in encoded form
   */
  __decodeJWT(token) {
    try {
      const decodedToken = jwtDecode(token);
      const decompressUserData = this.__unCompress(decodedToken.user_data);
      const parseUserData = JSON.parse(decompressUserData);
      const response = {
        ...decodedToken,
        ...{ user_data: parseUserData },
      };

      return response;
    } catch {
      if (this.onError)
        this.onError(status.invalidToken(ERROR_MESSAGE.INVALID_TOKEN));
      return {};
    }
  }

  // check if access token is set in the browser
  __checkAccessToken() {
    if (!this.accessToken) {
      this.__logout();
    }
  }

  // check if access and refresh tokens are set in the browser
  __checkRefreshToken() {
    if (!this.accessToken || !this.refreshToken) {
      this.__logout();
    }
  }

  /**
   * check provided permissions to see if any are present
   * @param {object} item containing permissions
   * @returns boolean
   */
  __hasPermissions(item) {
    return item?.Permissions?.length > 0;
  }

  // Get users ULP permissions if any
  __getULPPermissions() {
    if (!this.permissions?.Auth?.length) return null;

    return this.permissions.Auth.find(
      ({ ApplicationId }) => ApplicationId === this.config.__ULPID
    );
  }

  // Redirect to the ULP page
  __redirectToUlp(isError = false, ifUserLoggedOut = false) {
    const currentCount = BrowserStorageHelper.getItem(redirectCtName);
    if (currentCount >= 3) {
      // stop function here and throw error or modal.
      console.error(`[JsAuth]: Unified Authenticaton error: `, {
        error: ERROR_MESSAGE.AUTH_FAILED,
      });
      this.setAuthError?.({ message: ERROR_MESSAGE.AUTH_FAILED });
      return;
    }
    if (!this.disableRedirects) {
      BrowserStorageHelper.setItem(redirectCtName, currentCount + 1);
      this.__onStatus(STATUSES.REDIRECT_TO_ULP);
      redirectToUlp({
        env: envCache || this.env,
        isError,
        initialUserPath: this.initialUserPath,
        routerBaseName: this.routerBaseName,
        authErr: null,
        isExternalLogout: false,
        ifUserLoggedOut,
        isInternalApplication: this.isInternalApp,
      });
    }
  }

  // Check if the Token has expired
  __isTokenExpired() {
    return isExpired(this.userData?.exp);
  }

  // Check users current JWT and active login to find their permissions to the current entity
  __getUserAllPermissions() {
    if (!this.userData) return {};

    const dataKeys = Object.keys(this.userData);
    const findClaim = dataKeys.find(
      (key) => key.indexOf(this.config.__userDataKey) > -1
    );

    return this.userData[findClaim];
  }

  /**
   * Get legacy token user data
   * @param {object} token legacy JWT token
   * @returns `userdata` values from token
   */
  __getLegacyUserData(token) {
    if (!token) return [];

    const dataKeys = Object.keys(token);
    const findClaim = dataKeys.find(
      (key) => key.indexOf(this.config.__userDataLegacyKey) > -1
    );

    return token[findClaim];
  }

  // Match active entity with user permissions to find active permissions
  __getUserActivePermissions() {
    if (!this.permissions?.Auth?.length) return null;

    return this.permissions.Auth.find(
      ({ ApplicationId }) => ApplicationId === this.appId
    );
  }

  // Setup GET, POST, etc requests with request utility
  __setupRequests() {
    this.__REQUEST.INSTANCE = createAxiosInstance({
      accessToken: this.accessToken,
      entityId: this.entityId,
      appId: this.appId,
    });
    this.__REQUEST.GET = this.__REQUEST.INSTANCE.get;
    this.__REQUEST.POST = this.__REQUEST.INSTANCE.post;
  }

  /**
   * Check if the user has access to trigger callback function
   * @param {boolean} hasAccess
   * @param {function} callback
   * @returns {object} access error object
   */
  __checkAccess(hasAccess, success, failure) {
    if (hasAccess && success) {
      return success(this.env);
    }

    // trigger error callback if the user doesn't have access and callback was provided
    if (failure) return this.__accessError(failure);

    return "";
  }

  /**
   * Set Access token
   * @param {object} data response from api
   */
  __updateAccessToken(token) {
    // update access token
    if (token) {
      this.accessToken = token;
      // update user data
      this.userData = this.__decodeJWT(this.accessToken);
      this.__setCookie(this.CATID, this.accessToken, this.userData?.exp);
    }
  }

  /**
   * Set Refresh token
   * @param {object} data response from api
   */
  __updateRefreshToken(token) {
    // update refresh token
    if (token) {
      this.refreshToken = token;
      this.refreshData = this.refreshToken ? jwtDecode(this.refreshToken) : {};
      this.__setCookie(this.CRTID, this.refreshToken, this.refreshData?.exp);
    }
  }

  __onStatus(status) {
    if (this.onStatusUpdate) this.onStatusUpdate(`STATUS:: ${status}`);
  }

  /**
   * Trigger user does not have required permissions error
   * @param {function} callback
   */
  __missingPermissionsError(callback) {
    callback(
      status.error({
        message: ERROR_MESSAGE.PERMISSIONS_MISSING,
        success: false,
      })
    );
  }

  /**
   * Trigger missing environment param error on provided callback
   * @param {function} callback
   */
  __environmentError(callback) {
    callback(
      status.error({
        message: ERROR_MESSAGE.ENVIRONMENT_NOT_SET,
        success: false,
      })
    );
  }

  /**
   * Trigger user does not have required access error
   * @param {function} callback
   */
  __accessError(callback) {
    callback(
      status.error({
        message: ERROR_MESSAGE.INVALID_TOKEN,
        success: false,
      })
    );
  }

  /**
   * Trigger user does not have required access token cookies error
   * @param {function} callback
   */
  __missingTokensError(callback) {
    callback(
      status.error({
        message: ERROR_MESSAGE.ACCESS_TOKENS_MISSING,
        success: false,
      })
    );
  }
}

export default new Auth();
