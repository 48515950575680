import jwtDecode from "jwt-decode";
import parseISO from "date-fns/parseISO";
import subYears from "date-fns/subYears";
import formatISO from "date-fns/formatISO";
import isAfter from "date-fns/isAfter";

// We should adjust this to pull out all the token info. We may want to make a separate function aside from this
export const getTokenExp = (jwt) => {
  try {
    const decoded = jwtDecode(jwt);
    const exp = decoded?.expires_at;
    return exp;
  } catch {
    // expire the token if we get an exception to force logout.
    const d = new Date();
    return formatISO(subYears(d, 1));
  }
};

export const isTokenActive = (token) => {
  const isValid = isAfter(parseISO(getTokenExp(token)), new Date());
  return isValid;
};

export const isExpired = (epoch) => {
  const expDate = epoch < 1600000000000 ? epoch * 1000 : epoch;
  const currentTime = Math.trunc(Date.now());

  return currentTime > expDate;
};

export const doesUserHaveAccess = (permissions, requiredAccess) => {
  let count = 0;
  permissions.forEach((item) => {
    if (requiredAccess.includes(item)) {
      count += 1;
    }
  });

  return count > 0;
};
