import axios from "axios";
import { stringifyUrl, parse, exclude } from "query-string";

const apiBasePath = "ulpapi";
const apiProtocol = "https://";
const apiDomain = "cadent.tv";
const apiVersion = "v1";

const UiBasePath = "ulp/";
const UiProtocol = "https://";
const UiDomain = "cadent.tv";

export const QUERY_PARAM_NAMES = {
  sso: "sso",
  returnTo: "return_to",
  dlReturnTo: "dl_return_to",
  authErr: "auth_err",
  devReturn: "dev_return",
  extAppLogout: "ext_app_logout",
  isInternalApplication: "is_internal_app",
};

export const createUlpBase = (subdomain) =>
  `${UiProtocol}${subdomain}.${UiDomain}/${UiBasePath}`;

export const createUlpApiBase = (subdomain) =>
  `${apiProtocol}${subdomain}.${apiDomain}/${apiBasePath}/api/${apiVersion}`;

export const createUrlFromPath = (pathname, routerBaseName) => {
  const { protocol, host } = window.location;
  return `${protocol}//${host}${routerBaseName}${pathname}`;
};

export const ulpBase = {
  local: createUlpBase("cd"),
  development: createUlpBase("cd"),
  qa: createUlpBase("test"),
  demo: createUlpBase("uat"),
  stage: createUlpBase("stg"),
  production: createUlpBase("platform"),
};

export const ulpApiBase = {
  local: createUlpApiBase("cd"),
  development: createUlpApiBase("cd"),
  qa: createUlpApiBase("test"),
  demo: createUlpApiBase("uat"),
  stage: createUlpApiBase("stg"),
  production: createUlpApiBase("platform"),
};

export const createAxiosInstance = ({ accessToken, entityId, appId }) => {
  // set axios headers here.
  const axiosDefaults = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      // eslint-disable-next-line no-undef
      application_id: appId,
      entity_id: entityId,
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios.create(axiosDefaults);
};

export const getChangePasswordUrl = (env) => {
  const relativeUrl = "change-password";
  const base = ulpBase[env];
  const url = `${base}${relativeUrl}`;
  const query = { [QUERY_PARAM_NAMES.dlReturnTo]: window.location.href };
  return stringifyUrl({ url, query }, { skipNull: true });
};

export const generateULPBaseUrl = (env) => {
  if (!env) return "";
  return ulpBase[env];
};

export const getUserManagementUrl = (env) => {
  const relativeUrl = "user-management";
  return `${generateULPBaseUrl(env)}${relativeUrl}`;
};

export const getAdvertiserManagementUrl = (env) => {
  const relativeUrl = "advertisers";
  return `${generateULPBaseUrl(env)}${relativeUrl}`;
};

export const getAgenciesManagementUrl = (env) => {
  const relativeUrl = "agencies";
  return `${generateULPBaseUrl(env)}${relativeUrl}`;
};

export const trimTrailingSlash = (str) => str.replace(/\/$/, "");

export const getDevReturnTo = () => {
  const { origin } = window.location;
  const isLocalHost =
    origin.includes("localhost") ||
    origin.includes("127.0.0.1") ||
    origin.includes("0.0.0.0");
  return isLocalHost ? origin : null;
};

export const isStringUrl = (string) => {
  try {
    return Boolean(new URL(string));
  } catch (error) {
    return false;
  }
};
/**
 * Creates the ULP url based off of the env that the client is running in.
 * Detect the URL that the user should be returned to and adds it as the
 * @param {string} env current env FE is running
 * @returns formatted ULP url
 */
export const createUlpUrl = ({
  env,
  isError = false,
  initialUserPath,
  routerBaseName,
  authErr,
  isExternalLogout,
  ifUserLoggedOut,
  isInternalApplication: isIntAppArg,
}) => {
  // NOTE: determine if we need to exclude ulp specific query params.
  const { href, origin } = window.location;
  const {
    returnTo,
    authErr: authErrName,
    devReturn,
    extAppLogout,
    isInternalApplication,
    sso,
  } = QUERY_PARAM_NAMES;
  const isInitialPathFullUrl = isStringUrl(initialUserPath);

  let fullReturnToURL =
    initialUserPath && !isInitialPathFullUrl && href !== initialUserPath
      ? createUrlFromPath(initialUserPath, routerBaseName)
      : initialUserPath || href; // give precedence to initialUserPath.
  const { return_to } = parse(href);
  if (isError && return_to) {
    // if error we want to send them to the original URL they requested.
    fullReturnToURL = return_to;
  }
  // This will either be url or null
  const devReturnTo = getDevReturnTo();

  // on external application logout we want to send the user back to the root of the application they came from.
  if (ifUserLoggedOut || isExternalLogout) {
    fullReturnToURL = `${origin}${routerBaseName}`;
  }

  const queryParams = {
    [returnTo]: trimTrailingSlash(exclude(fullReturnToURL, [returnTo, sso])),
    [authErrName]: authErr,
    [devReturn]: devReturnTo,
    [extAppLogout]: ifUserLoggedOut || isExternalLogout,
    // if we don't have a truthy dev return value then we don't care if the application is internal or not.
    [isInternalApplication]: devReturnTo ? isIntAppArg : null,
  };
  return stringifyUrl(
    {
      url: ulpBase[env],
      query: queryParams,
    },
    { skipNull: true }
  );
};

export const redirectToUlp = (urlObj) => {
  window.location.replace(createUlpUrl(urlObj));
};

/**
 * Takes a string value or an Array of string values and determines if the currentPath should
 * matches the list of ignoredPaths.
 * @param {String or Array} ignoredPaths String or array of pathnames to ignore for authentication.
 * @param {String} currentPath String value for the current application path.
 * @returns Boolean
 */
export const isIgnoredPath = (ignoredPaths, currentPath) => {
  if (!ignoredPaths || !currentPath) return false;

  try {
    // gets the first /<pathname> out of a string.
    const regEx = /^\/([^?/]+)/g;
    const appPath = trimTrailingSlash(currentPath);

    const matchPath = (ignoredPath) => appPath.match(regEx)[0] === ignoredPath;

    if (typeof ignoredPaths === "string") {
      return matchPath(ignoredPaths);
    }

    return ignoredPaths.some((path) => matchPath(path));
  } catch (error) {
    return false;
  }
};
